<template>
  <MbnProfile :api-url="apiUrl" />
</template>

<script>
import MbnProfile from '@/components/Profile';

export default {
  name: 'ProfileWebmaster',
  components: {
    MbnProfile,
  },
  data() {
    return {
      apiUrl: '/webmaster/profile',
    };
  },
};
</script>
