<template>
  <div class="d-flex justify-content-center align-items-center">
    <CSpinner color="info" grow />
  </div>
</template>

<script>
export default {
  name: 'MbnSpinner',
};
</script>
