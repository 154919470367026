<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader class="d-flex align-items-center justify-content-between">
          <span>{{ $t('sidebar.profile') }}</span>
        </CCardHeader>
        <CCardBody>
          <MbnSpinner v-if="isLoading" />

          <MbnErrorMessage v-if="error" />

          <CRow v-if="userProfile">
            <CCol sm="12">
              <CInput size="sm" :label="$t('form.email')" readonly :value="userProfile.email" />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { GET_USER_PROFILE } from '@/store/action-types/profile';
import { mapState, mapActions } from 'vuex';
import MbnErrorMessage from '@/components/ErrorMessage';
import MbnSpinner from '@/components/Spinner';

export default {
  name: 'MbnProfile',
  components: {
    MbnErrorMessage,
    MbnSpinner,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.profile.data,
      isLoading: (state) => state.profile.isLoading,
      error: (state) => state.profile.error,
    }),
  },
  mounted() {
    this.GET_USER_PROFILE(this.apiUrl);
  },
  methods: {
    ...mapActions('profile', [GET_USER_PROFILE]),
  },
};
</script>
